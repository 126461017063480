export enum DLLFormat {
  KINDER = 'KINDER',
  GRADE = 'GRADE',
  MATATAG = 'MATATAG',
  ALS = 'ALS',
}

export const DDL_FORMAT = ['KINDER', 'GRADE', 'MATATAG', 'ALS'];

export const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

export enum Features {
  CATCH_UP_FRIDAYS = 'catch-up-fridays',
  SUMMATIVE_TEST = 'summative-test',
  ACTIVITIES = 'activity',
  QUIZMAKER = 'short-quiz',
  LEARNING_COMPETENCY = 'objectives',
  LESSON_PLAN = 'lesson-plan',
  UI_REVAMP = 'ui-revamp',
  CEREBRO_TEACHING_GUIDE = 'cerebro-teaching-guide',
  DLL = 'dll',
  OBJECTIVES = 'objectives',
}
